import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
} from "../../../../../global-state/redux";
import { getRequest } from "../../../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../../../core-ui";
const configHeaderTable = [
  "Plaque",
  "Model",
  "Carte Rose",
  "Convoyeur",
  "Montant Entré (Fbu)",
  "Gain Proprietaire (Fbu)",
  "Gain Nilpay (Fbu)",
  "Action",
];

const ListBusProprietaire = ({ tabData, userID }) => {
  const [dataTable, setdataTable] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getAllBusByProprietaire = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.bus}?proprietaire=${userID}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setdataTable(data);
  };
  useEffect(() => {
    getAllBusByProprietaire();
  }, [isSaved, tabData]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataTable &&
            dataTable.map(
              ({
                id,
                plaque,
                carte_rose,
                model,
                couleur,
                owner_bus,
                driver_bus,
                amount_paid,
                benefice_proprietaire,
                benefice_systeme,
              }) => {
                return (
                  <tr key={id}>
                    <td>{plaque}</td>
                    <td>{model}</td>
                    <td>{carte_rose}</td>
                    <td>{`${driver_bus.nom} ${driver_bus.prenom}`} </td>
                    <td>{`${amount_paid ? amount_paid : 0} Fbu`}</td>
                    <td>{`${
                      benefice_proprietaire ? benefice_proprietaire : 0
                    } Fbu`}</td>
                    <td>{`${benefice_systeme ? benefice_systeme : 0} Fbu`}</td>
                    <td>
                      <div className="actions">
                        <div className="btn success">
                          <span>{icons.edit}</span>
                        </div>

                        <div className="btn danger" onClick={() => {}}>
                          <img src={images.deleted} alt="delete categorie" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
      {!isloading && dataTable.length < 1 && (
        <TedEmpty description={"Aucun Bus trouvé"} icon={icons.empty} />
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default ListBusProprietaire;
