import icons from "./icons";
//MENU PRINCIPAL
const menu = {
  firstLevel: [
    {
      name: "Tableau de bord",
      icon: icons.dashboard,
      path: "/dashboard",
      activate: "dashboard",
      id: 1,
    },
    // {
    //   name: "Page-01",
    //   icon: icons.rapport,
    //   path: "/page-01",
    //   activate: "page-01",
    //   id: 2,
    // },
    // {
    //   name: "Tables",
    //   icon: icons.rapport,
    //   path: "/table",
    //   activate: "table",
    //   id: 3,
    // },
    // {
    //   name: "Presses",
    //   icon: icons.rapport,
    //   path: "/presses",
    //   activate: "presses",
    //   id: 9,
    // },

    // {
    //   name: "Seed",
    //   icon: icons.reunions,
    //   path: "/services",
    //   activate: "services",
    //   id: 3,
    // },
    {
      name: "Recettes",
      icon: icons.rh,
      path: "/recettes",
      activate: "recettes",
      id: 2,
    },
    {
      name: "Cartes",
      icon: icons.rh,
      path: "/cartes",
      activate: "cartes",
      id: 3,
    },
    {
      name: "Bus et Trajets",
      icon: icons.rh,
      path: "/bus et trajets",
      activate: "bus et trajets",
      id: 4,
    },
    {
      name: "Comptes",
      icon: icons.rh,
      path: "/comptes",
      activate: "comptes",
      id: 5,
    },
    {
      name: "Rapports",
      icon: icons.finance,
      path: "/rapports",
      activate: "rapports",
      id: 5,
    },
  ],
  secondLevel: [
    {
      name: "Configurations",
      icon: icons.param,
      path: "/configurations",
      activate: "configurations",
      id: 6,
    },
    {
      name: "Deconnexion",
      icon: icons.logout,
      path: "/",
      id: 7,
    },
  ],
};

//MENU TABULATIONS

const tabsComptes = [
  {
    id: 0,
    name: "TOUS",
    description: "TOUS LES COMPTES",
    clause: "",
    roleID: 0,
  },
  {
    id: 1,
    name: "CLIENT",
    description: "TOUS LES CLIENTS",
    clause: "?role=5",
    roleID: 5,
  },
  {
    id: 2,
    name: "AGENTS",
    description: "TOUS LES AGENTS",
    clause: "?role=3",
    roleID: 3,
  },
  {
    id: 3,
    name: "ADMINISTRATEURS",
    description: "TOUS LES ADMINSTRATEURS",
    clause: "?role=1",
    roleID: 1,
  },
  {
    id: 4,
    name: "CONVOYEUR",
    description: "TOUS LES CONVOYEURS",
    clause: "?role=2",
    roleID: 2,
  },
  {
    id: 5,
    name: "PROPRIETAIRE",
    description: "TOUS LES PROPRIETAIRES ",
    clause: "?role=4",
    roleID: 4,
  },
];
const tabsRecettes = [
  {
    id: 0,
    name: "Paiement",
    description: "HISTORIQUE DE PAIEMENT",
    clause: "",
  },
  {
    id: 1,
    name: "Recharges",
    description: "HISTORIQUE DE RECHARGE",
    clause: "",
  },
  {
    id: 2,
    name: "Retraits",
    description: "HISTORIQUE DE RETRAIT",
    clause: "",
  },
];
const tabsCartes = [
  {
    id: 0,
    name: "TOUTES",
    description: "TOUTES LE CARTES",
    clause: "",
  },
  {
    id: 1,
    name: "attribués",
    description: "TOUTES LE CARTES ATTRIBUES",
    clause: "?status=1",
  },
  {
    id: 2,
    name: "disponibles",
    description: "TOUTES LE CARTES DISPONIBLES",
    clause: "?status=0",
  },
];

const tabsTrajets = [
  {
    id: 0,
    name: "BUS",
    description: "TOUS LES BUS",
    clause: "",
  },
  {
    id: 1,
    name: "Trajets",
    description: "TOUS LES TRAJETS ET PRIX",
    clause: "",
  },
];
const tabsRapports = [
  {
    id: 0,
    name: "TOUS",
    description: "TOUS LES RAPPORTS",
    clause: "",
  },
];

const tabsParametre = [
  {
    id: 0,
    name: "Carte",
    description: "CONFIGURATION DE LA CARTE",
    clause: "",
  },
  {
    id: 1,
    name: "Intergration API",
    description: "COMPTE INTEGRATION API",
    clause: "",
  },
];

const tabsDetails = {
  proprietaire: [
    {
      id: 0,
      name: "Ses Bus",
      description: "TOUS LES BUS",
      clause: "",
    },
    {
      id: 1,
      name: "Ses Retraits",
      description: "TOUS LES RETRAITS",
      clause: "",
    },
  ],
  client: [
    {
      id: 0,
      name: "Ses Cartes",
      description: "TOUS LES CARTES",
      clause: "",
    },
    {
      id: 1,
      name: "Ses Recharge",
      description: "TOUS LES RECHARGES",
      clause: "",
    },
  ],
  convoyeur: [],
};

export default {
  menu,
  tabsComptes,
  tabsRecettes,
  tabsCartes,
  tabsTrajets,
  tabsRapports,
  tabsParametre,
  tabsDetails,
};
