import React from "react";
import "./Header.css";

//constants
import { icons, images } from "../../../constant";
import Lang from "../../lang/Lang";
import { useSelector, useDispatch } from "react-redux";

const Header = ({ pageName }) => {
  const dispatch = useDispatch();
  const { dataUser, isConnected } = useSelector((store) => store.user);
  const [nom, prenom, role] = [
    dataUser?.user?.nom,
    dataUser?.user?.prenom,
    dataUser?.user?.role.description,
  ];

  return (
    <header>
      <div>
        <h1>{pageName.toUpperCase()}</h1>
      </div>
      <div>
        <Lang />
        <div className="notifications">
          {icons.notification}
          <span>4</span>
        </div>
        <div className="avatar">
          <figure>
            <img src={images.avatar} alt="photo de profile" />
          </figure>
          <div className="identity">
            <span>{`${nom.toUpperCase()} ${prenom.toUpperCase()}`}</span>
            <span>{role}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
