import axios from "axios";
import { useSelector } from "react-redux";
const CONFIG_HEADER_FORM_DATA = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "",
  },
};
const CONFIG_HEADER_JSON = {
  headers: {
    "Content-Type": "application/json",
    Authorization: null,
  },
};

const setToken = () => {
  // const { dataUser } = useSelector((store) => store.user);
  // console.log("TOKEN", dataUser);
  const token = sessionStorage.getItem("xx_tk");
  CONFIG_HEADER_FORM_DATA.headers.Authorization = "Bearer " + token;
  CONFIG_HEADER_JSON.headers.Authorization = "Bearer " + token;
};

const postRequest = async (url, type, datas) => {
  const urls = `${process.env.REACT_APP_URL_API}${url}`;
  let err = false;
  let msg = null;
  let typeErr = "";
  let data = [];
  const headers =
    type === "json"
      ? { ...CONFIG_HEADER_JSON }
      : { ...CONFIG_HEADER_FORM_DATA };

  try {
    const response = await axios.post(urls, datas, {
      ...headers,
    });

    if (response.status === 200) {
      data = response.data.data;
    }

    if (response.status === 201) {
      msg = response.data.msg;
      typeErr = response.data.status;
      err = true;
    }
  } catch (error) {
    if (error.response.data !== undefined) {
      msg = error.response.data.msg;
      typeErr = error.response.data.status;
    } else {
      msg = "Veuillez verifier si vous êtes bien connecté à internet !";
      typeErr = "failed";
    }
    err = true;
  }

  return { err, msg, typeErr, data };
};

const getRequest = async (url) => {
  const urls = `${process.env.REACT_APP_URL_API}${url}`;
  let err = false;
  let msg = null;
  let typeErr = "";
  let data = [];
  setToken();
  try {
    const response = await axios.get(urls, CONFIG_HEADER_JSON);

    if (response.status === 200) {
      data = response.data.data;
    }

    if (response.status === 201) {
      msg = response.data.msg;
      typeErr = response.data.status;
      err = true;
    }
  } catch (error) {
    if (error.response.data !== undefined) {
      msg = error.response.data.msg;
      typeErr = error.response.data.status;
    } else {
      msg = "Veuillez verifier si vous êtes bien connecté à internet !";
      typeErr = "failed";
    }
    err = true;
  }

  return { err, msg, typeErr, data };
};

const patchRequest = async (url, type, datas) => {
  const urls = `${process.env.REACT_APP_URL_API}${url}`;
  let err = false;
  let msg = null;
  let typeErr = "";
  let data = [];
  const headers =
    type === "json"
      ? { ...CONFIG_HEADER_JSON }
      : { ...CONFIG_HEADER_FORM_DATA };

  try {
    const response = await axios.patch(urls, datas, {
      ...headers,
    });

    if (response.status === 200) {
      data = response.data.data;
    }

    if (response.status === 201) {
      msg = response.data.msg;
      typeErr = response.data.status;
      err = true;
    }
  } catch (error) {
    if (error.response.data !== undefined) {
      msg = error.response.data.msg;
      typeErr = error.response.data.status;
    } else {
      msg = "Veuillez verifier si vous êtes bien connecté à internet !";
      typeErr = "failed";
    }
    err = true;
  }

  return { err, msg, typeErr, data };
};

const deleteRequest = async (url) => {
  const urls = `${process.env.REACT_APP_URL_API}${url}`;
  let err = false;
  let msg = null;
  let typeErr = "";
  let data = [];
  setToken();
  try {
    const response = await axios.delete(urls, CONFIG_HEADER_JSON);

    if (response.status === 200) {
      data = response.data.data;
    }

    if (response.status === 202) {
      msg = response.data.msg;
      typeErr = response.data.status;
      err = true;
    }
  } catch (error) {
    if (error.response.data !== undefined) {
      msg = error.response.data.msg;
      typeErr = error.response.data.status;
    } else {
      msg = "Veuillez verifier si vous êtes bien connecté à internet !";
      typeErr = "failed";
    }
    err = true;
  }

  return { err, msg, typeErr, data };
};
export { postRequest, getRequest, patchRequest, deleteRequest };
