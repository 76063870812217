import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Comptes.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../services/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../components";
import { ListComptes, CreateComptes } from "./small";
const tabs = menu.tabsComptes;
const Comptes = () => {
  const dispatch = useDispatch();
  const { open, number } = useSelector((store) => store.modal);
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [statique, setStatique] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  //   const getAllStatique = async () => {
  //     dispatch(setLoading());
  //     const { err, msg, data, typeErr } = await getRequest(
  //       `${urls.statique}/all`
  //     );
  //     dispatch(setLoading());
  //     if (err) {
  //       return dispatch(setError({ err, msg, data, typeErr }));
  //     }
  //     setStatique(data);
  //   };
  useEffect(() => {
    // getAllStatique();
  }, []);
  return (
    <>
      <Container pageName={"Comptes"}>
        <HeaderPager
          title={tabIndex.description}
          showBtn={tabIndex.roleID > 0}
          btnTitle={"Ajouter"}
        />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        <ListComptes tabData={tabIndex} />
      </Container>

      {open && number === 0 && <CreateComptes roleId={tabIndex.roleID} />}
    </>
  );
};

export default Comptes;
