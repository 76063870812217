const DATA_DASHBOARD = [
  {
    id: 1,
    number: 0,
    name: "CLIENTS",
    key: "client",
  },
  {
    id: 2,
    number: 0,
    name: "AGENTS",
    key: "agents",
  },
  {
    id: 3,
    number: 0,
    name: "PROPRIETAIRE",
    key: "proprietaire",
  },
  {
    id: 4,
    number: 0,
    name: "BUS",
    key: "bus",
  },
  {
    id: 5,
    number: 0,
    name: "BENEFICE ACTUELLE",
    key: "beneficeTotal",
  },
  {
    id: 6,
    number: 0,
    name: "BENEFICE JOURNALIERE",
    key: "beneficeJournaliere",
  },
  {
    id: 7,
    number: 0,
    name: "MONTANT ENTRE",
    key: "montantEntreJournalier",
  },
  {
    id: 8,
    number: 0,
    name: "TOTAL RETRAIT",
    key: "retraitTotal",
  },
  {
    id: 9,
    number: 0,
    name: "TOTAL CARTE ATTRIBUEES",
    key: "cardAttribues",
  },
  {
    id: 10,
    number: 0,
    name: "TOTAL CARTE DISPONIBLE",
    key: "cardDisponible",
  },
];
export default {
  DATA_DASHBOARD,
};
