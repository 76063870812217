const formatterDate = (dates) => {
  const date = new Date(dates);
  const month = date.getMonth();
  const newmonth = month < 10 ? "0" + month : month;

  const day = date.getDate();
  const year = date.getFullYear();

  return day + "-" + newmonth + "-" + year;
};
const formatterdHour = (dates) => {
  const date = new Date(dates);
  return date.toLocaleTimeString("en-US", {
    hour12: false,
  });
};

const formatterDateAndHour = (dates) => {
  const date = formatterDate(dates);
  const heure = formatterdHour(dates);

  return `${date} ${heure}`;
};

export { formatterDate, formatterDateAndHour, formatterdHour };
