import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
} from "../../../../../global-state/redux";
import { getRequest } from "../../../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../../../core-ui";
const configHeaderTable = [
  "Numero carte",
  "Proprietaire",
  "Montant (Fbu)",
  "Status",
  "Etat",
  "Action",
];

const ListDeCarteClient = ({ userID }) => {
  const [dataTables, setDataTables] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getData = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.card}?userId=${userID}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataTables(data);
  };
  useEffect(() => {
    getData();
  }, [isSaved]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataTables &&
            dataTables.map((item) => {
              const { id, card_number, state, activated, amount, owner } = item;
              return (
                <tr key={id}>
                  <td>{card_number}</td>
                  <td>{owner ? owner.nom + " " + owner.prenom : "-"}</td>
                  <td>{amount} </td>
                  <td>
                    <span
                      className={`status ${state > 0 ? "success" : "danger"}`}
                    >
                      {state > 0 ? "attribuée" : "disponible"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`status ${
                        activated > 0 ? "success" : "danger"
                      }`}
                    >
                      {activated > 0 ? "activée" : "désactivé"}
                    </span>
                  </td>
                  <td>
                    <div className="actions">
                      {/* <div
                        className="btn warning"
                        title="Lier la carte"
                        onClick={() =>
                          dispatch(setOpenModal({ number: 1, data: item }))
                        }
                      >
                        <span>{icons.add_tab}</span>
                      </div> */}
                      <div className="btn success" title="Editer">
                        <span>{icons.edit}</span>
                      </div>
                      <div
                        className="btn danger"
                        onClick={() => {}}
                        title="Supprimer"
                      >
                        <img src={images.deleted} alt="delete categorie" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!isloading && dataTables.length < 1 && (
        <TedEmpty description={"Aucune carte trouvée"} icon={icons.empty} />
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default ListDeCarteClient;
