import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

//routes
import { useHistory } from "react-router-dom";

import { config } from "../../../constant";

//context gb state with
// import { useGlobalContext } from "../../../global-state/context/context";

// import { useGetLocalStorage } from "../../../hooks/useLocalStorage";
import { useSelector, useDispatch } from "react-redux";
import { setLoginUser } from "../../../global-state/redux";

//constants
import { menu } from "../../../constant";
const { firstLevel, secondLevel } = menu.menu;

const Navbar = () => {
  const history = useHistory();
  const path = history.location.pathname;
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  // console.log(rl);
  return (
    <div className="nav-container-main">
      <div>
        <h1>{config.nameAppLong}</h1>
      </div>
      <hr />

      <nav>
        <ul className="first-level">
          {firstLevel.map((menus, idx) => {
            return (
              <li key={idx}>
                <Link
                  to={menus.path}
                  className={
                    path.startsWith("/" + menus.name.toLowerCase())
                      ? "active"
                      : "no-active"
                  }
                >
                  <span>{menus.icon}</span>
                  {menus.name}
                </Link>
              </li>
            );
          })}
        </ul>

        <ul className="second-level">
          {secondLevel.map((menus, idx) => {
            return (
              <li key={idx}>
                {idx > 0 ? (
                  <Link
                    onClick={() => {
                      dispatch(
                        setLoginUser({ dataUser: {}, isConnected: false })
                      );
                    }}
                    to="/login"
                  >
                    <span>{menus.icon}</span>
                    {menus.name}
                  </Link>
                ) : (
                  <Link to={menus.path}>
                    <span>{menus.icon}</span>
                    {menus.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
