import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  number: 0,
  data: [],
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setOpenModal: (state, { payload }) => {
      state.open = !state.open;
      state.number = payload.number;
      state.data = payload.data;
    },
  },
});

export const { setOpenModal } = modalSlice.actions;
export default modalSlice.reducer;
