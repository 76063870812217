import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../../global-state/redux";
import "./Details.css";
import { data, urls, menu } from "../../../constant";
import { getRequest } from "../../../services/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../../components";
import {
  ListBusProprietaire,
  ListDeCarteClient,
  ListeDeRechargeClient,
  LiteDeRetraitProprietaire,
} from "./small";
const tabs = menu.tabsDetails;
const Details = () => {
  const dispatch = useDispatch();
  const { open, number } = useSelector((store) => store.modal);
  const { dataDetais } = useSelector((store) => store.data);
  const [tabIndex, setTabIndex] = useState(tabs[dataDetais.type][0]);
  const [statique, setStatique] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  useEffect(() => {}, []);
  return (
    <>
      <Container
        pageName={`${dataDetais.title} ${dataDetais.data.nom}  ${dataDetais.data.prenom}`}
      >
        <HeaderPager title={tabIndex.description} btnTitle="Ajouter" />
        <MenuPager tabs={tabs[dataDetais.type]} fxSelectedtab={tabChanging} />
        {/* for prorietaire */}
        {dataDetais.menu === "comptes" &&
          dataDetais.type === "proprietaire" &&
          tabIndex.id === 0 && (
            <ListBusProprietaire
              tabData={tabIndex}
              userID={dataDetais.data.id}
            />
          )}
        {dataDetais.menu === "comptes" &&
          dataDetais.type === "proprietaire" &&
          tabIndex.id === 1 && (
            <LiteDeRetraitProprietaire userID={dataDetais.data.id} />
          )}

        {/* for clients */}
        {dataDetais.menu === "comptes" &&
          dataDetais.type === "client" &&
          tabIndex.id === 0 && (
            <ListDeCarteClient userID={dataDetais.data.id} />
          )}
        {dataDetais.menu === "comptes" &&
          dataDetais.type === "client" &&
          tabIndex.id === 1 && (
            <ListeDeRechargeClient userID={dataDetais.data.id} />
          )}
      </Container>
      {/* {tabIndex.id === 0 && open && number === 0 && (
        <CreateUsers statique={statique} />
      )} */}
    </>
  );
};

export default Details;
