import React from "react";
import "./TedLoader.css";
import { config } from "../../constant";
import PropTypes from "prop-types";

const TedLoader = ({ size }) => {
  return (
    <div className={`loader ${size}`}>
      {!size && <span>{config.nameApp}</span>}
    </div>
  );
};

TedLoader.propTypes = {
  size: PropTypes.oneOf(["max", "min", "min01"]),
};

export default TedLoader;
