import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { setError, setLoading, setIsSaved } from "../../../global-state/redux";
import { getRequest } from "../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../core-ui";
import { formatterDateAndHour } from "../../../helpers";

const configHeaderTable = [
  "Date et Heure",
  "Client",
  "No Carte",
  "Plaque Bus",
  "Trajet",
  "Montant",
  "Action",
];

const ListPaiement = ({ tabData }) => {
  const [dataUsers, setDataUsers] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getData = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.paiement}`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }

    setDataUsers(data);
  };
  useEffect(() => {
    getData();
  }, [isSaved, tabData]);

  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataUsers &&
            dataUsers.map(
              ({
                id,
                date,
                createdAt,
                amount,
                client,
                bus_paiement,
                trajet,
                cards,
              }) => {
                return (
                  <tr key={id}>
                    <td>{formatterDateAndHour(createdAt)}</td>
                    <td>{`${client.nom} ${client.prenom}`}</td>
                    <td>{cards.card_number}</td>
                    <td>{bus_paiement.plaque}</td>
                    <td>
                      {`${trajet.source} - ${trajet.destination}`} <br />
                    </td>
                    <td>{amount}</td>
                    <td>
                      <div className="actions">
                        <div className="btn success">
                          <span>{icons.edit}</span>
                        </div>

                        <div className="btn danger" onClick={() => {}}>
                          <img src={images.deleted} alt="delete categorie" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
      {!isloading && dataUsers.length < 1 && (
        <TedEmpty description={"Aucun paiement trouvé"} icon={icons.empty} />
      )}

      {isloading && <Loader />}
    </div>
  );
};

export default ListPaiement;
