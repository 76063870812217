import {
  FaLock,
  FaUser,
  FaHome,
  FaFolder,
  FaHandshake,
  FaDollarSign,
  FaChartBar,
  FaBoxOpen,
  FaDolly,
  FaBell,
  FaCog,
  FaPowerOff,
  FaPlus,
  FaCheckCircle,
  FaExclamationCircle,
  FaTimesCircle,
  FaCaretDown,
  FaRegCheckCircle,
  FaRegCircle,
  FaTimes,
  FaRegFilePdf,
  FaRegEye,
  FaDownload,
  FaCloudDownloadAlt,
  FaSearch,
  FaPencilAlt,
  FaTrashAlt,
  FaPlay,
  FaFileMedical,
  FaQuestion,
  FaRegEdit,
  FaRegKeyboard,
  FaGlassWhiskey,
  FaCheck,
  FaShoppingCart,
  FaEye,
} from "react-icons/fa";

const icons = {
  user: <FaUser color="#073042" size={20} />,
  password: <FaLock color="#073042" size={20} />,
  dashboard: <FaHome color="#ffffff" size={15} />,
  rapport: <FaFolder color="#ffffff" size={15} />,
  reunions: <FaFolder color="#ffffff" size={15} />,
  finance: <FaFolder color="#ffffff" size={15} />,
  rh: <FaFolder color="#ffffff" size={15} />,
  materiel: <FaFolder color="#ffffff" size={15} />,
  param: <FaCog color="#ffffff" size={15} />,
  logout: <FaPowerOff color="#ffffff" size={15} />,
  notification: <FaBell color="#0369a1" size={25} />,
  success: <FaCheckCircle color="#047857" size={35} />,
  failed: <FaTimesCircle color="#be123c" size={35} />,
  failedd: <FaTimesCircle color="#be123c" size={25} />,
  add: <FaPlus color="#ffffff" size={13} />,
  adds: <FaPlus color="#fff" size={13} />,
  down: <FaCaretDown color="#0e7490" size={20} />,
  checked: <FaRegCheckCircle color="#047857" size={20} />,
  unchecked: <FaRegCircle color="#000000" size={20} />,
  close: <FaTimes color="#be123c" size={15} />,
  close_white: <FaTimes color="#fff" size={15} />,
  pdf: <FaRegFilePdf color="#047857" size={30} />,
  voir: <FaRegEye color="#fff" size={9} />,
  download: <FaDownload color="#fff" size={9} />,
  download_file: <FaCloudDownloadAlt color="#0e7490" size={60} />,
  download_file_true: <FaCloudDownloadAlt color="#047857" size={60} />,
  search: <FaSearch color="#44403c" size={25} />,
  pencil: <FaPencilAlt color="#44403c" size={25} />,
  delete: <FaTrashAlt color="#44403c" size={25} />,
  play: <FaPlay color="#ffffff" size={20} />,
  file: <FaFileMedical color="#44403c" size={25} />,
  question: <FaQuestion color="#44403c" size={25} />,
  text: <FaRegKeyboard color="#44403c" size={25} />,
  edite: <FaRegEdit color="#44403c" size={25} />,
  empty: <FaGlassWhiskey color="#be123c" size={50} />,
  checked_input: <FaCheck color="#ffffff" size={20} />,
  deleted: <FaTrashAlt color="#fff" size={15} />,
  edit: <FaRegEdit color="#fff" size={15} />,
  add_tab: <FaPlus color="#fff" size={15} />,
  stock: <FaShoppingCart color="#fff" size={15} />,
  details: <FaEye color="#fff" size={15} />,
};

export default icons;
