import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Page.css";
import { data, urls } from "../../constant";
import { getRequest } from "../../services/httpRequest";
//all components

import { Container } from "../../components";

const Page = () => {
  return (
    <>
      <Container pageName={"page"}></Container>
    </>
  );
};

export default Page;
