import { icons } from "../constant";
const configFormLogin = [
  {
    name: "username",
    type: "text",
    value: "",
    icon: icons.user,
    label: "Nom d'utilisateur",
  },
  {
    name: "password",
    type: "password",
    value: "",
    icon: icons.password,
    label: "Mot de passe",
  },
];

const configFormCreateComptes = {
  firstOne: [
    {
      name: "nom",
      type: "text",
      value: "",
      icon: "",
      label: "Nom",
    },
    {
      name: "prenom",
      type: "text",
      value: "",
      icon: "",
      label: "Prénom",
    },
    {
      name: "telephone",
      type: "text",
      value: "",
      icon: "",
      label: "Téléphone",
    },
    {
      name: "cni",
      type: "text",
      value: "",
      icon: "",
      label: "CNI",
    },
  ],

  secondOne: [
    {
      name: "percent_revenu",
      type: "text",
      value: "",
      icon: "",
      label: "Pourcentage sur revenus",
    },
    {
      name: "compteBanque",
      type: "text",
      value: "",
      icon: "",
      label: "N° compte Bancaire",
    },
  ],

  thirdOne: [
    {
      name: "username",
      type: "text",
      value: "",
      icon: "",
      label: "Identifiant de connexion",
    },
    {
      name: "password",
      type: "password",
      value: "",
      icon: "",
      label: "Mot de passe",
    },
  ],
};

const configFormCreateTrajet = [
  {
    name: "source",
    type: "text",
    value: "",
    icon: "",
    label: "Départ",
  },
  {
    name: "destination",
    type: "text",
    value: "",
    icon: "",
    label: "Déstination",
  },
  {
    name: "amount",
    type: "number",
    value: "",
    icon: "",
    label: "Montant à payer",
  },
];

const configFormCreateBus = [
  {
    name: "plaque",
    type: "text",
    value: "",
    icon: "",
    label: "Plaque",
  },
  {
    name: "carte_rose",
    type: "text",
    value: "",
    icon: "",
    label: "Carte Rose",
  },
  {
    name: "model",
    type: "text",
    value: "",
    icon: "",
    label: "Modèle",
  },
  {
    name: "couleur",
    type: "text",
    value: "",
    icon: "",
    label: "Couleur",
  },
  {
    name: "proprietaireId",
    type: "select",
    value: "",
    icon: "",
    label: "Proprietaire",
    options: [],
  },
  {
    name: "convoyeurId",
    type: "select",
    value: "",
    icon: "",
    label: "Convoyeur",
    options: [],
  },
];

const configFormCreateCards = [
  {
    name: "card_number",
    type: "text",
    value: "",
    icon: "",
    label: "Numero de la carte",
  },
];
const configFormLinkCards = [
  {
    name: "idUser",
    type: "select",
    value: "",
    icon: "",
    label: "Client",
    options: [],
  },
];

export {
  configFormLogin,
  configFormCreateComptes,
  configFormCreateTrajet,
  configFormCreateBus,
  configFormCreateCards,
  configFormLinkCards,
};
