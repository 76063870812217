import React, { useReducer, useEffect } from "react";
import { TedModal, TedButton } from "../../../core-ui";
import { Formulaire } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setIsSaved,
  setError,
} from "../../../global-state/redux";

import {
  reducer,
  configFormCreateCards,
  defaultValueFormCreateCards,
} from "../../../reducers";
import { postRequest } from "../../../services/httpRequest";
import { urls } from "../../../constant";

const TITLE_MODAL = ["AJOUTER UNE NOUVELLE CARTE"];

const CreateCards = ({ roleId }) => {
  const { open, number } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCreateCards
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCreateCards
  );

  const saveForm = async () => {
    dispatch(setLoading());
    const datas = { ...defaultValue, roleId };
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.card}`,
      "json",
      datas
    );

    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };
  useEffect(() => {}, []);

  return (
    <TedModal
      title={TITLE_MODAL[0]}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <Formulaire
        fields={configForm}
        dispatch={dispatchDefautlValue}
        values={defaultValue}
        align="column"
      />

      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label="ENREGISTRER"
          loading={isloading}
          fxOnClick={() => saveForm()}
        />
      </div>
    </TedModal>
  );
};

export default CreateCards;
