const urls = {
  login: "/auth/login",
  user: "/user",
  bus: "/bus",
  card: "/card",
  paiement: "/paiement",
  dashboard: "/dashboard",
};

export default urls;
