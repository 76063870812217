import React, { useEffect, useState } from "react";
import { icons } from "../../constant";

const TedSelect = ({ options, name, onChange }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState({ value: "", option: "" });

  const setSelectValue = (e, val) => {
    e.target.name = name;
    e.target.value = val;

    onChange(e);
    setValue({
      value: val,
      option: e.target.textContent,
    });
    setShowOptions(false);
  };
  useEffect(() => {}, []);
  return (
    <div
      className={`select ${showOptions || value.value ? "float" : "no-float"}`}
    >
      <div className="bar-select" onClick={() => setShowOptions(!showOptions)}>
        <div>
          <span>{value.option} </span>
        </div>
        <span>{showOptions ? icons.close : icons.down}</span>
      </div>
      <div className={`select-options ${showOptions ? "show-options" : ""}`}>
        <ul className="multi-select-ul">
          {options.map((item, idx) => {
            const { id, description } = item;
            return (
              <li
                key={idx}
                onClick={(e) => setSelectValue(e, id)}
                className={`${value.value === id ? "active" : "no-active"}`}
              >
                {description}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TedSelect;
