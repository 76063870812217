import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//all pages
import {
  Login,
  Error,
  Dashboard,
  Page,
  Table,
  Comptes,
  Recettes,
  Cartes,
  Trajets,
  Rapports,
  Parametre,
  Details,
} from "./pages";
import { useSelector, useDispatch } from "react-redux";
const App = () => {
  const dispatch = useDispatch();
  const { isConnected } = useSelector((store) => store.user);
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route
        exact
        path="/dashboard"
        render={() =>
          isConnected ? <Dashboard /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/comptes"
        render={() => (isConnected ? <Comptes /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/recettes"
        render={() => (isConnected ? <Recettes /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/cartes"
        render={() => (isConnected ? <Cartes /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/bus et trajets"
        render={() => (isConnected ? <Trajets /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/rapports"
        render={() => (isConnected ? <Rapports /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/configurations"
        render={() =>
          isConnected ? <Parametre /> : <Redirect to={"/login"} />
        }
      />

      {/* SECONDARY PAGES */}
      <Route
        exact
        path="/details"
        render={() => (isConnected ? <Details /> : <Redirect to={"/login"} />)}
      />

      <Route exact path="/page-01" render={() => <Page />} />
      <Route exact path="/table" render={() => <Table />} />

      <Route exact path="*">
        <Error />
      </Route>
    </Switch>
  );
};

export default App;
