import React, { useReducer, useEffect } from "react";
import { TedModal, TedButton } from "../../../core-ui";
import { Formulaire } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setIsSaved,
  setError,
} from "../../../global-state/redux";

import {
  reducer,
  configFormLinkCards,
  defaultValueFormLinkCards,
} from "../../../reducers";
import { postRequest } from "../../../services/httpRequest";
import { urls } from "../../../constant";

const TITLE_MODAL = ["LIER LA CARTE A UN CLIENT"];

const LinkCards = ({ usersGrouped }) => {
  const { open, number, data: modalData } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(reducer, configFormLinkCards);
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormLinkCards
  );

  const saveForm = async () => {
    dispatch(setLoading());
    const datas = { ...defaultValue };
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.card}/link-card-client/${modalData.id}`,
      "json",
      datas
    );

    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };
  useEffect(() => {
    dispatchConfig({ type: "SET_DATA_USER_CARD", payload: usersGrouped });
  }, []);

  return (
    <TedModal
      title={TITLE_MODAL[0]}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <p className="card-number">Numero carte : {modalData.card_number}</p>
      <Formulaire
        fields={configForm}
        dispatch={dispatchDefautlValue}
        values={defaultValue}
        align="column"
      />

      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label="LIER"
          loading={isloading}
          fxOnClick={() => saveForm()}
        />
      </div>
    </TedModal>
  );
};

export default LinkCards;
