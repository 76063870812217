import React, { useState } from "react";

const TedInputFile = ({ name, label, accept, fxOnchange, icons }) => {
  const [hasFile, setHasFile] = useState(false);
  const onChange = (e) => {
    fxOnchange(e);
    setHasFile(true);
  };
  return (
    <div className="file">
      <label htmlFor={name}>
        <input
          type="file"
          name={name}
          id={name}
          accept={accept}
          onChange={(e) => onChange(e)}
        />
        {hasFile ? icons.data : icons.nodata}
        <span className={hasFile ? "success" : "no-success"}>
          {hasFile ? label.data : label.nodata}
        </span>
      </label>
    </div>
  );
};

export default TedInputFile;
