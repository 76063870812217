import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
} from "../../../../../global-state/redux";
import { getRequest } from "../../../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../../../core-ui";
const configHeaderTable = [
  "Date",
  "Transaction ID",
  "Montant (Fbu)",
  "Status",
  "Action",
];

const LiteDeRetraitProprietaire = ({ tabData, userID }) => {
  const [dataTable, setdataTable] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getAllBusByProprietaire = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.paiement}/retrait?userID=${userID}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setdataTable(data);
  };
  useEffect(() => {
    getAllBusByProprietaire();
  }, [isSaved, tabData]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataTable &&
            dataTable.map(
              ({
                id,
                userId,
                amount,
                status,
                date,
                transactionID,
                createdAt,
                updatedAt,
              }) => {
                return (
                  <tr key={id}>
                    <td>{date}</td>
                    <td>{transactionID}</td>
                    <td>{`${amount ? amount : 0} Fbu`}</td>
                    <td>
                      {/* {status} */}
                      <div
                        className={`status ${
                          status === 0 ? "danger" : "success"
                        }`}
                      >
                        <span>{status === 0 ? "Echec" : "Succès"}</span>
                      </div>
                    </td>

                    <td>
                      <div className="actions">
                        <div className="btn success">
                          <span>{icons.edit}</span>
                        </div>

                        <div className="btn danger" onClick={() => {}}>
                          <img src={images.deleted} alt="delete categorie" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
      {!isloading && dataTable.length < 1 && (
        <TedEmpty description={"Aucun retrait effectué"} icon={icons.empty} />
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default LiteDeRetraitProprietaire;
