import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Trajets.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../services/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../components";
import { ListTrajet, CreateTrajet, ListBus, CreateBus } from "./small";
const tabs = menu.tabsTrajets;
const Trajets = () => {
  const dispatch = useDispatch();
  const { open, number } = useSelector((store) => store.modal);
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [usersGrouped, setUsersGrouped] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };
  const getAllUsersGroupedByRoles = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.user}/all`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setUsersGrouped(data);
  };
  useEffect(() => {
    getAllUsersGroupedByRoles();
  }, []);
  return (
    <>
      <Container pageName={"Trajets"}>
        <HeaderPager title={tabIndex.description} showBtn btnTitle="Ajouter" />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {tabIndex.id === 0 && <ListBus />}
        {tabIndex.id === 1 && <ListTrajet />}
      </Container>
      {tabIndex.id === 0 && open && number === 0 && (
        <CreateBus usersGrouped={usersGrouped} />
      )}
      {tabIndex.id === 1 && open && number === 0 && <CreateTrajet />}
    </>
  );
};

export default Trajets;
