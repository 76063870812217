import React, { useReducer, useEffect } from "react";
import { TedModal, TedButton } from "../../../core-ui";
import { Formulaire } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setIsSaved,
  setError,
} from "../../../global-state/redux";

import {
  reducer,
  configFormCreateComptes,
  defaultValueFormCreateComptes,
} from "../../../reducers";
import { postRequest } from "../../../services/httpRequest";
import { urls } from "../../../constant";

const TITLE_MODAL = [
  "",
  "CREER UN COMPTE ADMINISTRATEUR",
  "CREER UN COMPTE CONVOYEUR",
  "CREER UN COMPTE AGENT",
  "CREER UN COMPTE PROPRIETAIRE",
  "CREER UN COMPTE CLIENT",
];

const CreateComptes = ({ roleId }) => {
  const { open, number } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCreateComptes
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCreateComptes
  );

  const saveForm = async () => {
    dispatch(setLoading());
    const datas = { ...defaultValue, roleId };
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.user}`,
      "json",
      datas
    );

    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };
  useEffect(() => {}, []);

  return (
    <TedModal
      title={TITLE_MODAL[roleId]}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen"
    >
      <div className="form-create-user">
        <div>
          <Formulaire
            fields={configForm.firstOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
          {roleId === 4 && (
            <Formulaire
              fields={configForm.secondOne}
              dispatch={dispatchDefautlValue}
              values={defaultValue}
              align="column"
            />
          )}
        </div>
        <div>
          <Formulaire
            fields={configForm.thirdOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label="ENREGISTRER"
          loading={isloading}
          fxOnClick={() => saveForm()}
        />
      </div>
    </TedModal>
  );
};

export default CreateComptes;
