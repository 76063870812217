import React, { useEffect } from "react";
import "./TedErrorPopup.css";

import { icons } from "../../constant";

const TedErrorPopup = ({ errorsConfig, fxClosePopup }) => {
  const { showError, messageError, typeError } = errorsConfig;
  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        fxClosePopup();
      }, 5000);
    }
  }, [showError]);

  return (
    <div className={`error-popup ${typeError} ${showError ? "enter" : "quit"}`}>
      <div>{icons[typeError]}</div>
      <div>
        <span>{typeError.toUpperCase()}</span>
        <p>{messageError}</p>
      </div>
    </div>
  );
};

export default TedErrorPopup;
