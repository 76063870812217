import { createSlice } from "@reduxjs/toolkit";
import { startTransition } from "react";
import i18n from "../../../translations/i18n";

const initialState = {
  lang: i18n.language,
  idDataNewLangue: 0,
  dt: {},
  data_all: {},
  dataDetais: {
    menu: "", //comptes, bus-trajet, cartes, recettes
    type: "", //client, proprietaire, convoyeur
    title: "",
    id: "",
    data: [],
  },
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.lang = payload;
    },
    setNewIdLangue: (state, { payload }) => {
      state.idDataNewLangue = payload;
    },
    setDataAll: (state, { payload }) => {
      state.data_all = payload;
    },
    setDataDetails: (state, { payload }) => {
      state.dataDetais = payload;
    },
  },
});

export const {
  setLanguage,
  setNewIdLangue,
  setDt,
  setDataAll,
  setDataDetails,
} = dataSlice.actions;
export default dataSlice.reducer;
