import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Cartes.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../services/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../components";
import { ListCards, CreateCards, LinkCards } from "./small";
const tabs = menu.tabsCartes;
const Cartes = () => {
  const dispatch = useDispatch();
  const { open, number } = useSelector((store) => store.modal);
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [usersGrouped, setUsersGrouped] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  const getAllUsersGroupedByRoles = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.user}/all`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setUsersGrouped(data);
  };
  useEffect(() => {
    getAllUsersGroupedByRoles();
  }, []);
  return (
    <>
      <Container pageName={"Cartes"}>
        <HeaderPager
          title={tabIndex.description}
          btnTitle="Ajouter"
          showBtn={tabIndex.id < 1}
        />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        <ListCards tabData={tabIndex} />
      </Container>
      {tabIndex.id === 0 && open && number === 0 && <CreateCards />}
      {open && number === 1 && <LinkCards usersGrouped={usersGrouped} />}
    </>
  );
};

export default Cartes;
