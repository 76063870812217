import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
} from "../../../../global-state/redux";
import { getRequest } from "../../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../../core-ui";
const configHeaderTable = ["Départ", "Destination", "Montant", "Action"];

const ListTrajet = ({ tabData }) => {
  const [dataTable, setdataTable] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getTrajets = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.bus}/trajet`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setdataTable(data);
  };
  useEffect(() => {
    getTrajets();
  }, [isSaved, tabData]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataTable &&
            dataTable.map(({ id, source, destination, amount }) => {
              return (
                <tr key={id}>
                  <td>{source}</td>
                  <td>{destination}</td>
                  <td>{amount} Fbu</td>
                  <td>
                    <div className="actions">
                      <div className="btn success">
                        <span>{icons.edit}</span>
                      </div>

                      <div className="btn danger" onClick={() => {}}>
                        <img src={images.deleted} alt="delete categorie" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!isloading && dataTable.length < 1 && (
        <TedEmpty description={"Aucun compte trouvé"} icon={icons.empty} />
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default ListTrajet;
