const defaultValueFormLogin = {
  username: "",
  password: "",
};

const defaultValueFormCreateComptes = {
  nom: "",
  prenom: "",
  telephone: "+257",
  cni: "",
  roleId: "",
  compteBanque: "",
  username: "",
  password: "",
  percent_revenu: 0,
};

const defaultValueFormCreateTrajet = {
  source: "",
  destination: "",
  amount: 0,
};
const defaultValueFormCreateBus = {
  plaque: "",
  carte_rose: "",
  model: "",
  couleur: "",
  proprietaireId: "",
  convoyeurId: "",
};
const defaultValueFormCreateCards = {
  card_number: "",
};
const defaultValueFormLinkCards = {
  idUser: "",
};
export {
  defaultValueFormLogin,
  defaultValueFormCreateComptes,
  defaultValueFormCreateTrajet,
  defaultValueFormCreateBus,
  defaultValueFormCreateCards,
  defaultValueFormLinkCards,
};
