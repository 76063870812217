import React from "react";
import "./TedEmpty.css";

const TedEmpty = ({ icon, description }) => {
  return (
    <div className="empty">
      {icon}
      <p>{description}</p>
    </div>
  );
};

export default TedEmpty;
