import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Dashboard.css";

import { data, urls } from "../../constant";
import { getRequest } from "../../services/httpRequest";
//all components

import { Container } from "../../components";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((store) => store.data);
  const [dataD, setDataD] = useState([]);
  const getDashboard = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.dashboard}`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataD(data);
  };
  useEffect(() => {
    getDashboard();
  }, [lang]);
  return (
    <>
      <Container pageName={"tableau de bord"}>
        <div className="dashboard">
          <div className="cards-container">
            {data.DATA_DASHBOARD.map((item, idx) => {
              const newItem = { ...item, number: dataD[item.key] };
              // const newItem = item;
              return <CartItem key={idx} {...newItem} />;
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

const CartItem = ({ number, name }) => {
  return (
    <div className="cards">
      <div>
        <span>{number}</span>
      </div>
      <div>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default Dashboard;
