import React from "react";
import { images } from "../../constant";
import "./TedLoader.css";
const Loader = () => {
  return (
    <div className="loading">
      <div>
        <img src={images.loader} />
      </div>
      <span>chargement encours ...</span>
    </div>
  );
};
export default Loader;
