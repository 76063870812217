import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { setError, setLoading, setIsSaved } from "../../../global-state/redux";
import { getRequest } from "../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../core-ui";
import { formatterDateAndHour } from "../../../helpers";

const configHeaderTable = [
  "Date et Heure",
  "Client",
  "No Carte",
  "App",
  "Montant",
  "Action",
];

const ListeDeRecharge = ({ tabData }) => {
  const [dataTable, setDataTable] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getData = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.card}/recharge-card`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataTable(data);
  };
  useEffect(() => {
    getData();
  }, [isSaved, tabData]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataTable &&
            dataTable.map(({ id, createdAt, amount, app, card }) => {
              return (
                <tr key={id}>
                  <td>{formatterDateAndHour(createdAt)}</td>
                  <td>{`${card?.owner.nom} ${card?.owner.prenom}`}</td>
                  <td>{card.card_number}</td>
                  <td>{app ? app.app_name : "-"}</td>

                  <td>{amount}</td>
                  <td>
                    <div className="actions">
                      <div className="btn success">
                        <span>{icons.edit}</span>
                      </div>

                      <div className="btn danger" onClick={() => {}}>
                        <img src={images.deleted} alt="delete categorie" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!isloading && dataTable.length < 1 && (
        <TedEmpty description={"Aucun recharge trouvé"} icon={icons.empty} />
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default ListeDeRecharge;
