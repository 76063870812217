import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Table.css";
import { data, urls, images, icons } from "../../constant";
import { getRequest } from "../../services/httpRequest";
//all components
import { Container } from "../../components";
const NBRE_DATA = 10;

const Table = () => {
  return (
    <>
      <Container pageName={"Table"}>
        {/* 
        ###########################
        TABLE MODEL 01 
        ###########################
         */}
        <div className="tables">
          <div className="table-header">
            <div className="column-01">
              <span>Déscription</span>
            </div>
            <div className="column-02">
              <span>Déscription</span>
            </div>
            <div className="column-01">
              <span>Actions</span>
            </div>
          </div>
          <div className="table-content">
            <div className="column-01">
              <span>Bonjour description</span>
            </div>
            <div className="column-02">
              <span>Bonjour description</span>
            </div>
            <div className="actions column-01">
              <div className="btn success">
                <span>{icons.edit}</span>
              </div>
              <div className="btn danger" onClick={() => {}}>
                <img src={images.lang} alt="add content of another language" />
              </div>
            </div>
          </div>
        </div>

        {/* 
        ###########################
        TABLE MODEL 02
        ############################## 
        */}
      </Container>
    </>
  );
};

export default Table;
