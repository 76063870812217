export const loginImage = require("../assets/images/login.png");
export const avatar = require("../assets/images/default.png");
export const loader = require("../assets/images/spinner.gif");
export const deleted = require("../assets/images/delete.png");
export const bg_login_v1 = require("../assets/images/bg_log_v1.jpg");

export default {
  loginImage,
  avatar,
  deleted,
  bg_login_v1,
  loader,
};
