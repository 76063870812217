import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setError,
  setLoading,
  setIsSaved,
  setDataDetails,
} from "../../../global-state/redux";
import { getRequest } from "../../../services/httpRequest";
import { TedEmpty, Loader } from "../../../core-ui";
const configHeaderTable = [
  "Nom",
  "Prénom",
  "Tél",
  "CNI",
  "Rôle",
  "ID connexion",
  "Action",
];
const configHeaderTableProprietaire = [
  "Nom",
  "Prénom",
  "Tél",
  "CNI",
  "Rôle",
  "% Revenu",
  "Solde (Fbu)",
  "ID connexion",
  "Action",
];
const types = [
  "",
  "administrateur",
  "convoyeur",
  "agent",
  "proprietaire",
  "client",
];
const titles = [
  "",
  "DETAL DE L'ADMINISTRATEUR",
  "DETAIL DU CONVOYEUR",
  "DETAIL DE L'AGENT",
  "DETAIL DU PROPRIETAIRE",
  "DETAIL DU CLIENT",
];

const ListComptes = ({ tabData }) => {
  const history = useHistory();
  const [dataUsers, setDataUsers] = useState([]);
  const { isSaved, isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getUsers = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.user}${tabData.clause}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataUsers(data);
  };
  useEffect(() => {
    getUsers();
  }, [isSaved, tabData]);

  const tableHeaderData =
    tabData.id == 5 ? configHeaderTableProprietaire : configHeaderTable;
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {tableHeaderData.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!isloading &&
            dataUsers &&
            dataUsers.map((item) => {
              const {
                id,
                nom,
                prenom,
                cni,
                telephone,
                role,
                user,
                roleId,
                percent_revenu,
                solde_account,
              } = item;
              return (
                <tr key={id}>
                  <td>{nom}</td>
                  <td>{prenom}</td>
                  <td>{telephone}</td>
                  <td>{cni}</td>
                  <td>
                    {role?.description} <br />
                  </td>
                  {tabData.id == 5 && (
                    <>
                      <td>{`${percent_revenu}%`}</td>
                      <td>
                        {solde_account ? `${solde_account.amount}Fbu` : "0 Fbu"}
                      </td>
                    </>
                  )}
                  <td>{user && user[0]?.username}</td>
                  <td>
                    <div className="actions">
                      {(tabData.id === 1 || tabData.id === 5) && (
                        <div
                          className="btn warning"
                          onClick={() => {
                            const data = {
                              menu: "comptes",
                              type: types[roleId],
                              title: titles[roleId],
                              data: item,
                            };
                            dispatch(setDataDetails(data));
                            history.push("/details");
                          }}
                        >
                          <span>{icons.details}</span>
                        </div>
                      )}

                      <div className="btn success">
                        <span>{icons.edit}</span>
                      </div>
                      <div className="btn danger" onClick={() => {}}>
                        <img src={images.deleted} alt="delete categorie" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!isloading && dataUsers.length < 1 && (
        <TedEmpty description={"Aucun compte trouvé"} icon={icons.empty} />
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default ListComptes;
